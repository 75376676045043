.testimonial-cards {
    $bg: white !important;
    border-top: 4px solid $primaryColor !important;
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
    padding: 10px;
    //width: 300px !important;
    margin: 0 5px;
    position: relative;
    margin-top: 20px !important;
    backdrop-filter: blur(16px) saturate(180%) !important;
    -webkit-backdrop-filter: blur(16px) saturate(180%) !important;
    background-color: $bg;
    //box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    height: 250px !important;
    max-height: 250px !important;

    .testimonial-icon {
        position: absolute;
        left: 0;
        right: 0;
        top: -25px;
        width: 100%;
        display: flex;
        justify-content: center;
        user-select: none !important;

        .icon-wrapper {
            border-radius: 50%;
            background-color: $bg;
            padding: 10px;
            width: 50px;
            height: 50px;
            font-size: 15px !important;
            display: flex;
            justify-content: center;
            align-items: center;
            color: $primaryColor;
            //border-top: 4px solid $primaryColor !important;
            box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
        }
    }

    .card-body {
        text-align: left !important;
        display: flex;
        justify-content: center;
        flex-direction: column;
        height: 100%;

        span {
            color: lightgray !important;
        }
    }
}