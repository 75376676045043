@import "../styles/variables";

.wrapper-inner {
  width: 100%;
  background: $tripMerchanhtBG !important;
  ::selection {
    background: $primaryColor !important;
    color: white !important;
  }
}

.scroll-to-top {
  background-color: transparent !important;
  box-shadow: 0 9px 25px 0 rgb(132 128 177 / 28%);
  border: 2px solid gray !important;
  color: $primaryColor !important;
  border-radius: 50% !important;
}

.background-image {
  background-image: url(https://tripmerchant.com/wp-content/uploads/2019/06/Boat-in-Caramoan.jpg);
  background-repeat: no-repeat !important;
  background-size: cover !important;
  width: 100%;
  height: 75vh;

  @media (max-width: 600px) {
    background-position: center !important;
    height: auto;
  }
}
.bg-how-it-works {
  background-image: url(https://tripmerchant.com/wp-content/uploads/2019/06/Boat-in-Caramoan.jpg);
  background-repeat: no-repeat !important;
  background-size: cover !important;
  width: 100%;
  height: 90vh;

  @media (max-width: 850px) {
    background-position: center !important;
    height: 70vh;
  }
  @media (max-width: 750px) {
    background-position: center !important;
    height: 60vh;
  }
  @media (max-width: 650px) {
    background-position: center !important;
    height: 60vh;
  }
  @media (max-width: 600px) {
    background-position: center !important;
    height: 50vh;
  }
  @media (max-width: 400px) {
    background-position: center !important;
    height: 45vh;
  }
}

#video {
  width: 100%;
  min-height: 586px !important;
  position: fixed;
}
