.who-we-are {
  .wrapper {
    width: 100%;
    height: max-content;
    position: relative;
    cursor: pointer;
    img {
      width: 100%;
      height: 100%;
    }
    .on-hover {
      background-color: rgba(0, 0, 0, 0.6);
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      color: white;
      display: none;
      p {
        color: $primaryColor;
      }
      small {
        font-size: larger;
        color: $primaryColor;
      }
      .icon {
        font-size: larger;
      }
    }
    &:hover {
      .on-hover {
        display: block;
        background-color: rgba(0, 0, 0, 0.6);
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
      }
    }
  }
  .non-activeColor {
    background-color:  $primaryColor !important;
  }
  .button {
    width: 50%;
    height: auto;
    padding: 20px;
    text-align: left;
    color: white !important;
    font-size: large;
    border: 0;
    background: $primaryColorFade;
    @media #{$x-small-mobile} {
      width: 100%;
    }
  }
  .content-wrapper {
    width: 100%;
    height: auto;
    padding: 20px;
    p {
      font-size: medium;
    }
  }
}
