@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

@import "./styles/variables";
@import "./styles/index";

* {
  font-family: "Inter", sans-serif !important;
  // overflow-x: hidden;
}

body {
  background: #999999 !important;
  font-family: "Inter", sans-serif !important;
  font-style: normal !important;
  font-weight: normal !important;
}

a {
  color: inherit !important;
  text-decoration: none !important;
  /* no underline */
}

a:hover {
  color: inherit;
  text-decoration: none;
  /* no underline */
}

html {
  scroll-behavior: smooth;
}

[data-aos] {
  pointer-events: none !important;
}

.aos-animate {
  pointer-events: auto !important;
}

.aos-init[data-aos][data-aos].aos-animate {
  transform: unset !important;
}

/* Firefox */
* {
  scrollbar-width: auto;
  scrollbar-color: $primaryColor #ffffff;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 7px;
  height: 2px;
}

*::-webkit-scrollbar-track {
  background: black;
}

*::-webkit-scrollbar-thumb {
  background-color: $primaryColor;
  border-radius: 10px;
  border: 0.1px solid $primaryColor;
}

.pointer {
  cursor: pointer;
}

.bg-cust-primary {
  background-color: $primaryColor !important;
}

.cust-primary-color {
  color: $primaryColor !important;
}

.bg-trip-primary {
  background-color: $tripMerchanhtBG !important;
}

.text-unselectable {
  cursor: pointer;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.hover-effect:hover {
  background-color: $primaryColorFade !important;
  color: $white !important;
  border-color: $primaryColorFade !important;
}

.hover-text:hover {
  color: $primaryColor !important;
}

.font-100 {
  font-weight: 100;
}

.font-200 {
  font-weight: 200;
}

.font-300 {
  font-weight: 300;
}

.font-400 {
  font-weight: 400;
}

.font-500 {
  font-weight: 500;
}

.font-600 {
  font-weight: 600;
}

.font-700 {
  font-weight: 700;
}

.font-800 {
  font-weight: 800;
}

.font-900 {
  font-weight: 900;
}

.font-nrm {
  font-size: 14px;
}

.font-xsm {
  font-size: 15px;
}

.font-sm {
  font-size: 16px;
}

.font-md {
  font-size: 17px;
}

.font-lg {
  font-size: 20px !important;
  line-height: 26px !important;

  @media #{$small-mobile} {
    font-size: 17px !important;
    line-height: 21px;
  }
}

.font-xl {
  font-size: 25px;

  @media #{$small-mobile} {
    font-size: 20px !important;
  }
}

.font-xml {
  font-size: 32px;

  @media #{$small-mobile} {
    font-size: 29px !important;
  }
}

.font-xxl {
  font-size: 45px;

  @media #{$small-mobile} {
    font-size: 28px !important;
  }
}

button,
input[type="submit"],
input[type="reset"] {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

.background-cover {
  background-repeat: no-repeat !important;
  background-size: cover !important;
}

.border-radius-7{
  perspective: 1000px;
  border-radius: 7px;
  overflow: hidden;
}

.button-rot {
  position: relative;
  width: $button-width;
  height: $button-height;
  perspective: 1000px;
  border-radius: 7px;
  overflow: hidden; // Ensures children respect the border-radius

  > div {
    margin: 0;
    left: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    font-weight: 500;
    font-family: inherit;
    font-size: 14px;
    line-height: 1.7em;
    text-transform: uppercase;
    position: absolute;
    transform-style: preserve-3d;
    transform: translateZ(-($button-height / 2));
    transition: transform $button-transition-speed;

    &:before,
    &:after {
      justify-content: center;
      align-items: center;
      margin: 0;
      width: $button-width;
      height: $button-height;
      position: absolute;
      box-sizing: border-box;
      border-radius: inherit; // Inherit rounded corners from parent

      content: attr(data-tooltip);
      display: flex;
    }

    &::-ms-tooltip {
      display: none;
    }

    &:before {
      background-color: $primaryColor;
      color: $white;
      transform: rotateY(0deg) translateZ($button-height / 2);
    }

    &:after {
      background-color: $primaryColorFade;
      color: $white;
      transform: rotateX(90deg) translateZ($button-height / 2);
    }

    &:hover {
      transform: translateZ(-($button-height / 2)) rotateX(-90deg);
    }
  }
}

.rotate-opposite {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}

.width-100 {
  width: 100% !important;
}

.border-radius {
  border-radius: 5px;
}

.text-shadow-navbar {
  background-color: white;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  background-clip: text;
  color: transparent;
  text-shadow: rgba(245, 245, 245, 0.5) 0px 2px 1px;
}

.cursor-pointer {
  cursor: pointer !important;
}

.text-decoration {
  text-decoration: underline !important;
}

.border-gray-bottom {
  border-bottom: 8px solid #222;
}

.danger-html {
  p,
  span {
    font-family: "Inter", sans-serif !important;
  }

  p,
  span,
  li {
    font-size: 20px !important;
    font-weight: 400px;
  }

  a {
    text-decoration: underline !important;
    color: $primaryColorFade !important;

    &:hover {
      color: $primaryColor !important;
    }
  }

  &.list-style {
    ul {
      list-style: "*" !important;
    }
  }
}

.button-33 {
  background: #fc4a1a; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #fc4a1a,
    #f7b733
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #fc4a1a, #f7b733); /* Standard syntax */
  border-radius: 100px;
  box-shadow: rgba(242, 89, 38, 0.2) 0 -25px 18px -14px inset,
    rgba(242, 89, 38, 0.15) 0 1px 2px, rgba(242, 89, 38, 0.15) 0 2px 4px,
    rgba(242, 89, 38, 0.15) 0 4px 8px, rgba(242, 89, 38, 0.15) 0 8px 16px,
    rgba(242, 89, 38, 0.15) 0 16px 32px;
  color: #fff; /* Adjusted text color to white for better visibility */
  cursor: pointer;
  display: inline-block;
  font-family: CerebriSans-Regular, -apple-system, system-ui, Roboto, sans-serif;
  padding: 7px 20px;
  text-align: center;
  text-decoration: none;
  transition: all 250ms;
  border: 0;
  font-size: 16px;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-33:hover {
  box-shadow: rgba(242, 89, 38, 0.35) 0 -25px 18px -14px inset,
    rgba(242, 89, 38, 0.25) 0 1px 2px, rgba(242, 89, 38, 0.25) 0 2px 4px,
    rgba(242, 89, 38, 0.25) 0 4px 8px, rgba(242, 89, 38, 0.25) 0 8px 16px,
    rgba(242, 89, 38, 0.25) 0 16px 32px;
  transform: scale(1.05) rotate(-1deg);
}
