$button-height: 60px;
$button-width: 160px;
$button-spacing: 15px;
$button-transition-speed: 0.25s;

._banner {
  padding: 2rem !important;
  color: $white !important;
  // min-height: 500px !important;
  .wrapper {
    p {
      margin: 1em !important;
      font-size: 20px;
      font-weight: 400;
      line-height: 25px;
      @media (max-width: 650px) {
        font-size: 16px;
        line-height: 20px;
      }
    }
  }
}

._banner--2 {
  // height: 550px;
  padding: 2rem !important;
  padding-top: 3rem !important;
  color: black !important;
  @media #{$sm-layout} {
    height: 300px;
  }
  // min-height: 600px !important;
  .wrapper {
    // width: 100%;

    p {
      // margin: 1em !important;
      font-size: 20px;
      font-weight: 400;
    }
    .button {
      background-color: $primaryColor;
      color: $white !important;
      border: 0 !important;
      width: auto;
      height: 60px;
      padding: 10px;
      margin: 0 5px;
      text-transform: uppercase;
      &:hover {
        background-color: $primaryColor;
        font-weight: bold !important;
      }
    }
  }
}
