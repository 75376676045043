$grad: linear-gradient(
  0deg,
  rgba(0, 0, 0, 0.5),
  rgba(0, 0, 0, 0.5),
  rgba(0, 0, 0, 0.5)
);
.partner-height {
  min-height: 360px;
  .membership-card {
    height: 360px !important;
    background-image: $grad, url("../assets/images/italy.jpg");
    background-position: center;
  }
  .employee-card {
    height: 360px !important;
    background-image: $grad, url("../assets/images/taj.jpg");
    background-position: center;
  }
  .travel-card {
    height: 360px !important;
    background-image: $grad, url("../assets/images/australia1.jpg");
    background-position: center;
  }
  @media (max-width: 900px) {
    min-height: 280px !important;
    .membership-card {
      height: 280px !important;
    }
    .employee-card {
      height: 280px !important;
    }

    @media (max-width: 500px) {
      min-height: 260px;
      .membership-card {
        height: 260px !important;
      }
      .employee-card {
        height: 260px !important;
      }
    }
  }
}
