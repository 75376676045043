

@media screen and (max-width: 425px) {
  .col_lg_7 {
    width: auto !important;
  }
}

@media screen and (max-width: 375px) {
  .display-gone {
    display: none;
    visibility: hidden;
  }
}

@media screen and (max-width: 475px) {
  .form-responsive-padding {
    padding: 0 10px !important;
  }
}

.rs-picker-toggle-wrapper {
  display: block !important;
  width: auto !important;
}

/* .rs-stack-item {
  display: none !important;
  visibility: hidden !important;
}
 */
.rs-picker-daterange-calendar-group {
  @media #{$large-mobile} {
    display: flex !important;
    flex-direction: column !important;
    height: auto !important;
    min-width: auto !important;
  }
}

@media #{$large-mobile} {
  .rs-picker-daterange-menu .rs-calendar:first-child {
    border-right: 0 !important;
    border-right: 0 !important;
  }
}
