.last-deals-card {
  height: auto !important;
  padding: 0 !important;
  margin: 0 !important;
}

.deals-card {
  .deals--card {
    height: 381px;
    background-position: center !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    border-radius: 10px;
    cursor: pointer;
    position: relative;
  }

  .text-position {
    color: white !important;
    flex-direction: column;
    position: absolute;
    bottom: 10px;

    .title {
      font-weight: 500;
      font-size: 15px;
      margin: 0 10px !important;
      text-align: initial !important;
    }

    .subtitle {
      font-weight: 300;
      font-size: 12px;
      margin: 0 10px !important;
      text-align: initial !important;
    }
  }

  .learn-more {
    color: $primaryColor;
    font-weight: 300;
    font-size: 16px;
    cursor: pointer;
    text-decoration: underline;

    .icon {
      font-weight: bolder !important;
      margin-left: 10px;
    }
  }

  .outer-title {
    font-weight: 400;
    font-size: 18px;
    margin: 0 !important;
    color: #7c889b;
  }

  .outer-subtitle {
    font-weight: 400;
    font-size: 20px;
    color: #1a2234;
    padding-bottom: 10px;
    border-bottom: 1px solid $primaryColor;
  }

  .author {
    color: #a1a1a1;
    font-size: 18px !important;
    font-weight: 400 !important;
    line-height: 30px !important;
    letter-spacing: -0.015em !important;

    span {
      color: black;
    }
  }

  @media #{$small-mobile} {
    padding: 0 20px !important;
  }
}

.deals-icon-bg {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 30px;
  color: white;
  width: auto;
  background: "#A4A4 !important";
  background-color: #a4a4a4 !important;
  border-radius: 200px;
}
.price {
  margin: 0;
  font-size: small;
  font-weight: normal;
}
.strike-through {
  text-decoration: line-through;
  font-size: 11px;
  font-weight: 700;
  color: #b0baba;
  letter-spacing: 0.69px;
  line-height: 14px;
}
