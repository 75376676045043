.how-it-works {
  overflow-x: hidden !important;

  ._border {
    width: 90%;
    border-radius: 50%;
    border: 10px solid #e16e44;
    height: 280px;
    display: flex;
    justify-content: center;
    align-items: center;
    .icon {
      font-size: 180px;
      font-weight: bold;
    }
  }
  .detail {
    color: $primaryColor;
    margin: 10px 0;
  }
  .breadcrumbs-two {
    overflow: hidden;
    width: 100%;
    list-style: none;
    display: flex;
    justify-content: center;
    color: white !important;
    z-index: 0;

    @media #{$sm-layout}{
      display: block;
    }
  }
  .breadcrumbs-two li {
    float: left;
    width: 100%;
    padding: 0 30px;
  }
  .breadcrumbs-two a {
    text-transform: uppercase;
    float: left;
    text-decoration: none;
    color: #fff;
    position: relative;
    text-align: center;
    width: 100%;
    height: 80px;
    line-height: 1.3;
    font-size: 15px;
  }
  .breadcrumbs-two a span {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 70px;
    align-items: center;
    text-align: center;
  }
  .breadcrumbs-two a::before {
    content: "";
    position: absolute;
    top: 50%;
    margin-top: -40px;
    border-width: 40px 0 40px 30px;
    border-style: solid;
    border-color: #ddd #ddd #ddd transparent;
    left: -30px;
  }
  .breadcrumbs-two a::after {
    content: "";
    position: absolute;
    top: 50%;
    margin-top: -40px;
    border-top: 40px solid transparent;
    border-bottom: 40px solid transparent;
    border-left: 30px solid #ddd;
    right: -30px;
  }
  .bread1 a {
    background-color: #eca186;
  }
  .bread1 a::before {
    border-color: #eca186 #eca186 #eca186 transparent;
  }
  .bread1 a::after {
    border-color: transparent #eca186 transparent #eca186;
  }
  .bread2 a {
    background-color: #f3875f;
  }
  .bread2 a::before {
    border-color: #f3875f #f3875f #f3875f transparent;
  }
  .bread2 a::after {
    border-color: transparent #f3875f transparent #f3875f;
  }
  .bread3 a {
    background-color: $primaryColor;
  }
  .bread3 a::before {
    border-color: $primaryColor $primaryColor $primaryColor transparent;
  }
  .bread3 a::after {
    border-color: transparent $primaryColor transparent $primaryColor;
  }
  .icon {
    font-weight: bolder;
    font-size: 30px;
    color: white !important;
  }
}

.how-it-works-style-2{
  overflow-x: hidden !important;
  .circle{
    display: flex;
    justify-content: flex-end;
    margin-right: -20px;
    color: white !important;
    margin-top: -10px;
    @media #{$sm-layout}{
      display: none;
      visibility: hidden;
    }
  }
  .border{
    border: 0 !important;
    border-right: 2px solid white !important;
    @media #{$sm-layout}{
      border-right: 0 !important;
    }
  }
  .image{
    width: 80%;
    img{
      width: 100%;
      height: 100%;
    }
  }
  .wrapper{
    margin: 30px;
  }
  .detail{
    width: 350px;
    margin-top: 40px;
    @media #{$sm-layout}{
      width: auto !important;
    }
  }
  .button{
    padding: 20px;
    width: auto;
    height: auto;
    background: $primaryColor;
    border-radius: 5px;
    color:$white;
    &:hover{
      background-color: #f3875f;
      font-weight: 500;
    }
  }
}
