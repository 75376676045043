.page-not-found{
    

    p{
        color:#303030;
    }

    .btn-border{
        border:2px solid black !important;
        padding: 8px;
        width: 195px;
        text-align: center;
        margin-top: 20px;
        margin-bottom: 70px;
        border-radius: 5px;
    }
}