.footer {
  background-color: #000000 !important;
  color: $white !important;
  z-index: 0 !important;
  .container-padding {
    padding-top: 2rem;
    .col1 {
      img {
        width: 189px;
      }
      a {
        cursor: pointer;
        &:hover {
          color: $primaryColor !important;
        }
      }
    }
    .col2 {
      h2 {
        font-size: 16px;
        line-height: 34px;
        font-weight: 700;
      }
      p {
        cursor: pointer;
        font-size: 16px;
      }
      ul {
        list-style: none !important;
        text-align: left !important;
        columns: 2;
        -webkit-columns: 2;
        -moz-columns: 2;
        cursor: pointer;
        width: max-content;
        a {
          color: inherit;
          text-decoration: none;
        }
        li {
          line-height: 22px;
          font-size: 14px;
          &:hover {
            color: $primaryColor;
          }
        }
      }
    }
    .col3 {
      h2 {
        font-size: 16px;
        font-weight: 700;
        line-height: 30px;
        margin-left: -10px;
      }
      .input-container {
        width: 325px;
        border-radius: 200px;
        border: 1px solid $white;
        padding: 5px;
        height: 44px;
        input {
          width: 164px;
          border: 0 !important;
          background: transparent;
          color: $white;
          margin: 4px;
          &:active {
            border: none !important;
          }
          &:focus {
            outline: none !important;
            border: none !important;
          }
        }
        button {
          border-radius: 200px;
          border: 1px solid $primaryColor;
          background-color: $primaryColor;
          color: $white;
          width: 110px;
          height: 32px;
        }
        @media #{$sm-layout} {
          width: 100% !important;
        }
        @media #{$small-mobile} {
          input {
            width: 150px;
          }
          button {
            width: 100px;
          }
        }
      }
    }
    .social-icon {
      width: 37px;
      height: 37px;
      border: 1px solid $white;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 5px !important;
      padding: 5px !important;
      color: $white !important;
      cursor: pointer;
      .icon-color {
        font-size: larger;
        font-weight: bold;
      }
      &:hover {
        border: 1px solid $primaryColor !important;
        color: $primaryColor !important;
      }
    }
    hr {
      /* margin: 1rem; */
      color: $primaryColor !important;
      border: 0;
      border-top: 1px solid $primaryColor !important;
      opacity: 1;
    }
    @media #{$small-mobile} {
      .col1,
      .col2 {
        border-bottom: 1px solid gray;
        margin: 10px 0;
      }
      .col2 {
        padding-bottom: 10px;
      }
    }
    button {
      background-color: $primaryColor;
      color: $white;
      border-radius: 5px;
      border: 0;
      text-align: center;
      padding: 10px;
      width: 100px;
      &:hover {
        background-color: $primaryColorFade;
      }
    }
  }
  .m-container-padding {
    padding-top: 2rem !important;
    .--row {
      display: flex !important;
      width: 100% !important;
      .col--8 {
        width: 60%;
        .image {
          width: 127px;
          height: 47px;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .list--style {
          list-style: none;
          color: gray;
          text-align: left !important;
          display: block;
          margin-left: -20px;
        }
      }
      .col--4 {
        width: 40%;
        .list--style {
          list-style: none;
          color: gray;
          text-align: left !important;
          display: block;
          margin-left: -20px;
        }
      }
    }
  }
}

.button-modal {
  background-color: $primaryColor !important;
  color: $white !important;
  border-color: $primaryColor !important;
  &:hover {
    background-color: $primaryColorFade !important;
  }
}

.scroll-to-top {
  background-color: $primaryColor !important;
  color: $white;
  border-radius: 50% !important;
  border: none !important;
  box-shadow: none !important;
  position: fixed;
}
