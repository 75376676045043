.home-wrapper {
  min-height: 733px !important;
}

.header-container {
  background-color: rgba(0, 0, 0, 0.5);
}

.header-container-light {
  background-color: rgba(0, 0, 0, 0.3);
}

/* top-bar */
.top-bar {
  .logo {
    width: 180px !important;
    margin: 5px;
    filter: drop-shadow(2px 1px rgb(0 0 0 / 0.4));

    @media #{$sm-layout} {
      width: 140px !important;
    }

    // @media (max-width:1100) {
    //   width: 120px !important;
    // }
    // @media (max-width: 570px) {
    //   width: 100px !important;
    // }
  }

  .menu-icon {
    display: flex !important;
    align-items: center;
    text-align: center;
    justify-content: center;
  }

  .display-menu {
    display: none;
    visibility: hidden;

    @media #{$md-layout} {
      display: block;
      visibility: visible;
    }

    @media #{$sm-layout} {
      display: block;
      visibility: visible;
    }
  }

  .hide-display {
    @media #{$large-mobile} {
      display: none;
      visibility: hidden;
    }
  }

  .button {
    display: flex;
    height: 40px;
    padding-inline: 8px;
    font-size: 14px;
    border: 1px solid $white;
    justify-content: center;
    align-items: center;
    border-radius: 5px;

    .icon {
      color: $white;
    }

    &:hover {
      border: 1px solid $primaryColor !important;
      color: $primaryColor !important;
    }

    @media #{$small-mobile} {
      span {
        font-size: 13px;
      }
    }
  }

  .hide-display {
    @media #{$large-mobile} {
      display: none;
      visibility: hidden;
    }
  }
}

/* navbar */
.navbar {
  color: $white !important;

  .parent-list {
    width: 100%;
    list-style: none;
    display: flex;
    margin: 0;
    left: 0;
    padding: 0 30px;

    li {
      padding: 0 10px;
      cursor: pointer;

      .child-list {
        width: 180px;
        font-size: 13px;
        display: none !important;
        position: absolute;
        color: $white;
        list-style: none;
        z-index: 1000 !important;
        background-color: black;
        margin: 0px;
        padding: 10px;

        li {
          margin-bottom: 8px;

          a {
            &:hover {
              color: $primaryColor !important;
            }
          }
        }
      }

      a {
        &:hover {
          color: $primaryColor !important;
        }
      }

      &:hover {
        .child-list {
          display: block !important;
        }
      }
    }

    @media #{$lg-layout} {
      font-size: 13px !important;
      padding: 0 10px;
    }

    @media #{$md-layout} {
      font-size: 12px !important;
      padding: 0 5px;
    }
  }

  @media #{$sm-layout} {
    display: none !important;
    visibility: none !important;
  }
}

.mobile-menu {
  display: none;

  [data-aos] {
    pointer-events: none;

    &.aos-animate {
      pointer-events: auto;
    }
  }

  .backdrop {
    background-color: rgba(0, 0, 0, 0.6);
    position: absolute;
    width: 100%;
    height: 100vh;
    left: -1500px;
    z-index: 100;
    top: 0;
    transition: all 0.5s ease-in-out;
  }

  .backdrop-show {
    left: 0 !important;
    overflow-y: hidden;
    position: fixed;
  }

  .menu {
    background: $primaryColor;
    height: 100vh;
    width: 290px;
    position: absolute;
    top: 0;
    left: -300px;
    padding: 40px;
    transition: all 0.75s ease-in-out;
    color: $white;
    z-index: 100;

    /* opacity: 0; */
    .list--style {
      list-style: none;
      padding-left: 0;
      margin-top: 20px;
      display: block;

      a {
        color: inherit;
        text-decoration: none;
      }

      li {
        padding: 5px 0;
        line-height: 1.5rem;
      }
    }
  }

  .active {
    left: 0 !important;
    /* opacity: 1 !important; */
    overflow-y: hidden;
    position: fixed;
  }

  @media #{$md-layout} {
    display: block;
  }

  @media #{$sm-layout} {
    display: block;
  }
}

.--header {
  backdrop-filter: blur(16px) saturate(180%);
  -webkit-backdrop-filter: blur(16px) saturate(180%);
  background-color: rgba(17, 25, 40, 0.15);

  &.header-light-fixed {
    z-index: 100;
    top: 0;
    left: 0;
    right: 0;
    color: $white !important;
    border-color: $white !important;
    transition: 0.3s;
    /* background: transparent; */
    position: absolute;

    @media #{$large-mobile} {
      position: relative;
    }
  }

  &.header-dark-fixed {
    position: relative;
    top: 0;
    left: 0;
    right: 0;
    color: $white !important;
    border-color: $white !important;
    transition: 0.3s;
    /* background-color: rgba(211, 221, 240, 0.15);
    background: transparent;
    backdrop-filter: blur(16px) saturate(180%);
    -webkit-backdrop-filter: blur(16px) saturate(180%); */
    z-index: 100;
  }

  &.header--sticky {
    top: 0;
    position: fixed !important;
    left: 0;
    right: 0;
    color: $white !important;
    z-index: 100;
    backdrop-filter: blur(16px) saturate(180%);
    -webkit-backdrop-filter: blur(16px) saturate(180%);
    background-color: rgba(17, 25, 40, 0.75);
  }
}

.header-bg-transparent {
   backdrop-filter: none;
  -webkit-backdrop-filter: none;
  background-color: transparent;
  /* position: relative !important;  */
}