@import "../../../styles/variables";
.reset-password {
    height: 100px;
  .signin-input {
    padding: 10px 18px;
    border: 0;
    border-radius: 4px;
    color: #fff !important;
    background: #333;
    border: 2px solid #333;
    input {
      background: transparent !important;
      border: none !important;
      outline: none !important;
      width:100% !important;
      color: #fff !important;
      &:focus,&:active,&:hover{
        border: none !important;
        outline: none !important;
      }
      
    }
    &:focus {
        outline: none;
        width: 90%;
        padding: 10px 20px;
        border: 2px solid $gray;
     }
  }
  .cursor-pointer{
    cursor: pointer;
  }
}