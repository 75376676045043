$primaryColor : #F25926;
$primaryColorFade : #f57e56;
$white : #fff;
$black : #000;
$gray : gray;
$tripMerchanhtBG: #f6f6f6;

// media query screen sizes
$extra-large-device: 'only screen and (min-width: 1600px) and (max-width: 1919px)';
$laptop-device: 'only screen and (min-width: 1400px) and (max-width: 1599px)';
$lg-layout: 'only screen and (min-width: 992px) and (max-width: 1199px)';
$md-layout:'only screen and (min-width: 768px) and (max-width: 991px)';
$sm-layout:'only screen and (max-width: 768px)';
$large-mobile: 'only screen and (max-width: 575px)';
$small-mobile: 'only screen and (max-width: 475px)';
$x-small-mobile: 'only screen and (max-width: 375px)';