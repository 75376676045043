@import "../../../styles/variables";

$cardSquare : 290px;
$cardMdLg : 250px;
$cardMeduim : 230px;
$cardSmall : 180px;
$borderWidth : 1px solid $primaryColor;
$fontSize: 20px;

.full-screen-bg{
  background-image: linear-gradient(0deg,rgba(0,0,0,.8),rgba(0,0,0,.5),rgba(0,0,0,.8)),url("../../../assets/images/homepage-background.jpg");
  background-size:cover;
  background-repeat:no-repeat;
  background-position:center center;
  min-height: 100vh !important

}
.main-container{
    min-height: 80vh;
    padding:20px;
    width: 100%;
    .logo{
        height: 80px;
    }
    .button-header-login{
      display: flex;
      height: 40px;
      padding-inline: 8px;
      border: 1px solid $white;
      justify-content: center;
      align-items: center;
      .icon-user{
        color: $white;
      }

    
      &:hover{
        border: 1px solid $primaryColor !important;
        button{
          color: $primaryColor;
        }
        .icon-user{
          color: $primaryColor !important
        }
      }
      @media (max-width: 768px) {
        .icon-user{
          font-size: 14px !important;
        }
      }
      @media (max-width: 575px) {
        .icon-user{
      font-size: 12px !important;}
      }
    }
    .button-login{
        height: 30px;
        margin-top: 10px;
        color: $primaryColor;
        font-weight: bold;
        cursor: pointer;
        -webkit-user-select: none; 
        -ms-user-select: none; 
        user-select: none; 
    }
    .cards-container{
        flex: 1;
    }

    .icon-container{
        background-color: $primaryColor;
        margin:7px;
        height:35px;
        width: 35px;
        border-radius: 50%;
        cursor: pointer;
    }
    .icon-container:hover{
      background-color: $primaryColorFade;
    

  }
}

.card-container{
    border: $borderWidth;
    height:$cardSquare !important;
    padding:20px !important;
    line-height: 30px !important;
    border-radius: 16px;
    box-shadow: 0 0 1rem 0 rgba(0, 0, 0, .2); 
    border-radius: 5px;
    background-color: rgba(255, 255, 255, .15);
    // z-index: 0 !important;
    // backdrop-filter: blur(2px);
    .text-label{
        font-size: $fontSize;
        color: $white;
        font-weight: 700;
        text-align: left;
        text-align: center;
    }
    .btn-styles{
        background-color: $primaryColor;
        border: transparent;
        border-radius: none !important;
        cursor: pointer;
        text-align: center;
        font-size: 17px;
        font-weight: 600;
        -webkit-user-select: none;        
        -moz-user-select: none; 
        -ms-user-select: none; 
        user-select: none; 
        border-radius: 5px;
    }
}

@media (min-height: 1200px) {
  .main-container{  min-height: 800px;}

}

@media (max-width:1200px){
  .card-container{
    padding: 30px!important;
    line-height: 27px;

    .text-label{
      font-size: 21px;
  }
  }
}

  @media (max-width: 1024px) {
  
    .card-container {

        padding:20px !important;
        height: $cardMeduim !important;
        // width: $cardMeduim;

      .text-label{
      font-size: 17px;
      line-height: 22px
    }}
  }

  @media (max-width: 768px) {
  
  .card-container {
    padding:20px !important;

    .text-label{
    font-size: 17px;
    line-height: 23px;
    text-align: center;
  }}
}
  @media (max-width: 575px) {
  
    .card-container{
        display: block;
        margin-top:30px !important;
        border: $borderWidth !important;
        height: $cardSmall;
        margin-left: 20px !important;
        margin-right: 20px !important;
    }
}

@media (max-width: 548px) {
   .main-container {
    
    .logo {
    height: 70px;
   }
.button-login{
    font-size: 13px;
}}
.cards-container{
  margin-left: 20px !important;
    margin-right: 20px !important;
    display: block;
}
  }

