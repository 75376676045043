.schedule-demo {
  .button {
    background: $primaryColor;
    width: auto;
    padding: 10px;
    color: $white;
    &:hover {
      background-color: coral;
    }
  }
}
