@import "../../../styles/variables";

.signup-container {
  /*  min-width: 100vw; */

  min-height: 100vh;

  .description-container {
    // padding-inline: 40px;
    color: $white;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
      url("../../../assets/images/signup.JPEG");
    background-size: cover;

    .trip-merchant-heading {
      font-weight: 700;
      padding-left: 15px;
      border-left: 4px solid $white;
    }

    .united-subheading {
      font-weight: 600;
      margin-top: 20px;
      margin-bottom: 20px;
    }

    .btn-loyality {
      background-color: #fff;
      width: 180px;
      color: $primaryColor;
      padding-block: 8px;
      font-weight: 500;
      border-radius: 5px;
      margin-bottom: 30px;
      cursor: pointer;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }
    .custom-margin {
      @media #{$laptop-device} {
        margin-top: -4rem !important;
      }
      //margin-top: -4rem !important;
    }
  }

  .form-container {
    padding-inline: 40px;

    .signup-heading {
      font-weight: bold;
    }

    .registered-text {
      font-size: 14px;
      color: $gray;
      line-height: 19px;
      margin-bottom: 10px;
    }

    .primary-color {
      color: $primaryColor;
      font-size: 14px;
      font-weight: 500;
      text-decoration: none;
    }

    .icon-unselectable {
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }
  }

  .form-check-input {
    &:checked {
      background-color: $primaryColor;
      border-color: $primaryColor;
    }
  }
}

@media (max-width: 992px) {
  .signup-container {
    .description-container {
      padding-top: 40px;
    }
  }
}

@media (max-width: 1200px) {
  .signup-container {
    .description-container {
      padding-top: 40px;
    }

    .form-container {
      padding: 30px;
    }
  }
}

@media (max-width: 580px) {
  .signup-container {
    .form-container {
      padding: 20px;
    }
  }
}

@media (max-width: 320px) {
  .width-100 {
    width: 100% !important;
  }

  .width-80 {
    width: 80% !important;
  }
}
