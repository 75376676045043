@import "./landing-page";
/* @import "./header"; */
@import "./social-icons";
@import "./banner";
@import "./about";
@import "./partner-banner";
@import "./how-it-works";
@import "./benefits";
@import "./footer";
@import "./whoweare";
@import "./schedule-demo";
@import "./partnerwithus";
@import "./employees";
@import "./header2";
@import "./membership-based";
@import "./override";
@import "./not-found-404";
@import "./ResponseModal";
@import "./info_section";
@import "./trips";
@import "./info-warning-banner";
@import "./trip-merchant-journeys";
@import "./testimonial-cards";

.bg-background-primary {
  background-color: $primaryColor !important;
  color: white !important;
  cursor: pointer !important;
  border-color: $primaryColor !important;
}

.blur-filter-overlay {
  backdrop-filter: blur(3px) saturate(180%);
  -webkit-backdrop-filter: blur(16px) saturate(180%);
  background-color: rgba(0, 0, 0, 0.04);
}

.modal--close-button {
  position: absolute;
  top: 10px;
  right: 10px;
}

.trip-merchant-journey-logo {
  width: 300px;

  @media #{$large-mobile} {
    width: 200px;
  }
}

.subscribe-news-letter {
  display: flex;
  border: 1px solid #dee2e6;
  border-radius: 10px;
  background: transparent !important;

  //padding: 0 10px !important;
  //width: 80%;
  .wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center !important;

    //width: 100%;
    input {
      flex: 1 0 auto;
      border: none !important;
      outline: none !important;
      background: transparent !important;
      padding: 5px;
      margin: 0 10px;

      @media #{$large-mobile} {
        //margin-left: 10px;
        flex: none !important;
        //width: 70%;
      }
    }

    button {
      flex: 0 0 auto;
      border-top-left-radius: 0 !important;
      border-bottom-left-radius: 0 !important;
      padding: 5px;
    }
  }
}

.horizontal-line {
  border-bottom: 1px solid #dee2e6;
  flex: 1;
  filter: drop-shadow(0 0 0.75rem #222);
}

.gap {
  gap: 5px;
}
