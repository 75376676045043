@import "../../../styles/variables";

.forget-password-form-container{

    .signup-fields-container{
        background-color: rgba(0,0,0,0.8);
        padding: 50px 60px 40px;
        min-height: 350px;
        
        .signin-heading{
        font-size: 32px;
        font-weight: 500;
        margin-bottom: 28px;
        }
     .input-error-container   
    {
        height: 70px !important;
        .signin-input{
        width: 100%;
        padding: 10px 18px;
        border: 0;
        border-radius: 4px;
        color: #fff;
        background: #333;
        border: 2px solid #333;

    }
    .signin-input:focus {
        outline: none;
        width: 100%;

        padding: 10px 20px;
        border: 2px solid $gray;
     }}
     .checkbox{
        background-color: #b3b3b3;
     }
     .checkbox-text{
        color: #b3b3b3;
    font-size: 13px;
    font-weight: 400;
    margin-left: 8px;
     }
     .signup-text{
        margin-top: 10px;
        color: #b3b3b3;
        font-size: 14px;
     }
     @media (max-width: 575px) {
       
     
            
                 padding: 30px 25px 10px !important;}
     
}
}
