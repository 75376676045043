._partner_banner {
  .banner-card {
    max-height: 250px !important;
    margin-bottom: 5px;
    .image-container {
      background: url(https://tripmerchant.com/wp-content/uploads/2018/08/italy2-1.jpg);
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      width: 100%;
      height: 200px;
    }
    .detail{
      display: flex;
      justify-content: center;
      text-align: center;
      color: #fff;
      font-size: larger;
      font-weight: 500;
      width: 100%;
      align-items: center;
      height: 250px;
    }
    .button{
        background-color: $primaryColor;
        color: $white;
        width: 100%;
        margin-top: 5px;
        padding: 10px;
        text-align: center;
        font-weight: bold;
    }
  }
}
