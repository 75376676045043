.social-icons{
    border-radius: 50%;
    border: 1px solid $primaryColor;
    background: $primaryColor;
    width: 2em;
    height: 2em;
    color: $white;
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    margin: 0 10px;
    cursor: pointer;
}