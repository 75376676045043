@import "../../../styles/variables";

.adjust-margin{
    margin-top: 15px !important;
}

.input-container-border{
    border: 2.5px solid rgb(255, 255, 255);
    padding: 10px;
    
    .input-border:focus{
        outline: none;
    }

    .input-border::placeholder{
        color: rgb(157, 157, 157);
    }
    
}

@media (max-width: 769px) {
    .input-container-border{
     width: 100% !important;
     margin-bottom: 8px;
    }
    .adjust-margin{
        margin-top: 0px !important;
    }
    
}

@media (max-width: 480px) {
    .input-container-border{
        width: 100% !important;
        margin-bottom: 8px;
    }
    .adjust-margin{
        margin-top: 0px !important;
    }
}