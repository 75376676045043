@import "../../../styles/variables";

.signin-form-container1 {
  .signup-fields-container1 {
    background-color: rgba(0, 0, 0, 0.8);
    padding: 50px 60px 40px;

    .signin-heading1 {
      font-size: 30px;
      font-weight: 500;
      margin-bottom: 28px;
      text-align: "center";
    }
  }
  .button {
    width: 100% !important;
    background-color: $primaryColor !important;
    border-color: $primaryColor !important;
    &:hover {
      background-color: $primaryColorFade !important;
      border-color: $primaryColorFade !important;
    }
  }
}

@media (max-width: 575px) {
  .signin-form-container {
    .signup-fields-container {
      padding: 30px 30px 20px;
    }
  }
}
