@import "../../../styles/variables";



.main-button-container{
background-color: $primaryColor;
color: $white;
-webkit-user-select: none;        
-moz-user-select: none; 
-ms-user-select: none; 
user-select: none; 
cursor:pointer
}