.info_warning_banner {
    .banner {
        background-color:black;
        background: url(../assets/images/Cloud_Surf.jpg);
        //padding: 20px;
        text-align: center;
        font-size: 16px;
        color: #fff;
        background-position: center !important;
        background-repeat: no-repeat !important;
        background-size: cover !important;

        p {
            margin: 0;
        }

        strong {
            font-weight: bold;
        }

        .highlight {
            color: #ff6600;
            font-weight: bold;
        }

        .emphasis {
            font-style: italic;
        }

        .feature {
            margin-top: 10px;

            ul {
                list-style-type: disc;
                margin-left: 20px;
            }

            li {
                margin-bottom: 5px;
            }
        }

    }
    .overlay {
        background-color: rgba(0, 0, 0, 0.7);
        height: 100% !important;
      }
      .wrapper{
        padding: 20px !important;
      }
}