
.form-width-container{
    width: 500px;
.inputs{

    width: 100%;
    padding-inline: 20px;
    padding-block: 10px;
    border: none;
    outline: none;
    margin-bottom: 14px;
    color: $gray;
}

.inputs-select{
    width: 100%;
    border: none;
    outline: none;
    margin-bottom: 14px;
    height: 44px;
    color: $gray;

}}