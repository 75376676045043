.response-modal {

    $green: #4cc93f;
    $icon-base-size: 20px;

    .modal-bg{
        backdrop-filter: blur(16px) saturate(180%) !important;
        -webkit-backdrop-filter: blur(16px) saturate(180%) !important;
        background-color: rgba(17, 25, 40, 0.75) !important;
    }
    .response-modal-button{
        background-color: $primaryColor !important;
        border: none !important;
        outline: none !important;
        &:hover{
            background-color: $primaryColorFade !important;
        }
    }
    
    .cursor-close{
        cursor: pointer;
        color: $primaryColor;
        font-size: 20px;
        &:hover{
            color: $primaryColorFade;
        }
    }

    .success-icon {
        display: inline-block;
        width: 8em;
        height: 8em;
        font-size: $icon-base-size;
        border-radius: 50%;
        border: 4px solid lighten($green, 20%);
        background-color: #fff;
        position: relative;
        overflow: hidden;
        transform-origin: center;
        animation: showSuccess 580ms ease-in-out;
        transform: scale(1);
    }

    // Elements
    .success-icon {

        &__tip,
        &__long {
            display: block;
            position: absolute;
            height: 4px;
            background-color: lighten($green, 20%);
            border-radius: 10px;
        }

        &__tip {
            width: 2.4em;
            top: 4.3em;
            left: 1.4em;
            transform: rotate(45deg);
            animation: tipInPlace 300ms ease-in-out;
            animation-fill-mode: forwards;
            animation-delay: 280ms;
            visibility: hidden;
        }

        &__long {
            width: 4em;
            transform: rotate(-45deg);
            top: 3.70em;
            left: 2.75em;
            animation: longInPlace 140ms ease-in-out;
            animation-fill-mode: forwards;
            visibility: hidden;
            animation-delay: 500ms + 140ms;
        }

    }

    @keyframes showSuccess {
        from {
            transform: scale(0);
        }

        to {
            transform: scale(1);
        }
    }

    @keyframes tipInPlace {
        from {
            width: 0em;
            top: 0em;
            left: -1.6em;
        }

        to {
            width: 2.4em;
            top: 4.3em;
            left: 1.4em;
            visibility: visible;
        }
    }

    @keyframes longInPlace {
        from {
            width: 0em;
            top: 5.1em;
            left: 3.2em;
        }

        to {
            width: 4em;
            top: 3.70em;
            left: 2.75em;
            visibility: visible;
        }
    }

    #svgContainer {
  max-width: 100%;
  max-height: 100%;
  background-image: linear-gradient(70deg, #f0f0f0 10%, #ffffff 100%);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  text-align: center;
}

#svgContainer svg {
  width: 20%!important;
}
}