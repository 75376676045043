@import "../../styles/variables";
$inputBorder: 1px solid gray;


.input-container{
    border: $inputBorder;
    padding:5px;
    border-radius: 5px;
    padding-left: 15px;
    margin-bottom:20px;

    .input{
        border: none;
        padding-left: 10px;
        flex:1
    }

       .input:focus{
        border: none;
        outline: none;
    }

    .empty{
        width: 16px;
        height: 16px;
    }
}

.error-style{
    font-size: 13px;
    font-weight: 500;
    padding-bottom: 10px;
    margin: 0px;
    margin-left: 10px !important;
}