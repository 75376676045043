._about {
  background: $tripMerchanhtBG !important;
  .about-section {
    height: auto;
    display: flex;
    flex-direction: column;
    position: absolute;
    text-align: center;
    justify-content: center;
    width: 80%;
    margin: 10px;
  }
}
