$size:270px;
$mdSize:180px;
$smSize:160px;

.application-process-container{
    display: flex;
    
.process-circle{
    width: $size;
    height: $size;
    border: 10px solid $primaryColor;
    position: relative;
    .top-right {
        position: absolute;
        top: 8px;
        right: 19px;
        font-size: 18px;
        height:40px;
        width:40px;
        background-color: $primaryColor;
        
      }

    }
    
    @media screen and (max-width: 1000px) {
        .process-circle{ width: $mdSize;
     height: $mdSize;
    }
    @media screen and (max-width: 800px) {

        .process-circle{ width: $smSize;
     height: $smSize;
     border: 7px solid $primaryColor;

     .top-right {
      right: 5px;
      
    }}}

    @media screen and (max-width: 600px) {
        display: block !important;

    }
}
}



