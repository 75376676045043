.benefits {
  background: $tripMerchanhtBG !important;
  .benefits-card {
    width: 60%;
    .card-container {
      width: 100%;
      height: 200px;
      border-radius: 50%;
      border: 2px solid $primaryColor;
      justify-content: center;
      background-color: $primaryColor !important;
      display: flex;
      align-items: center;
      text-align: center;
      .icon {
        font-size: 100px;
        color: $primaryColor;
      }
    }
  }
  .detail {
    font-size: medium;
    margin: 10px 0;
  }

  
}

.benefits-style-2 {
  .wrapper {
    padding: 20px;
  
  }


  .card {
    
    border-top: 5px solid $primaryColor !important;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    background-color: $white;
     cursor: pointer;
    .icon-container {
      display: flex;
      justify-content: center;
      .icon {
        color: $primaryColor !important;
        font-size: 60px;

      }
    }
    .detail-container {
      h3 {
        margin: 5px 0;
      }
      p {
        margin: 5px 0;
        color: gray;
      }
    }
    &:hover {
      box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;  
    }
    @media #{$sm-layout} {
      border-top: 5px solid $primaryColor !important;
      box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    }
  }
}

.image-style-1{
  width: auto;
  height: auto;
  @media #{$sm-layout} {
    width: 250px !important;
  }
}