.trip-merchant-journeys {
  .font-bold {
    font-weight: 600;
  }
  .main-container-general {
    min-height: 88vh;
    padding: 20px;
    width: 100%;

    .move-up-down {
      -webkit-animation: action 1s infinite alternate;
      animation: action 1s infinite alternate;
    }

    @-webkit-keyframes action {
      0% {
        transform: translateY(0);
      }

      100% {
        transform: translateY(20px);
      }
    }

    @keyframes action {
      0% {
        transform: translateY(0);
      }

      100% {
        transform: translateY(-20px);
      }
    }
  }

  .border-button {
    border: 4px solid $white;
    color: $white;
    padding: 10px;
    margin: 16px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  // .benefit-container {
  //     display: flex;
  //     justify-content: center;
  //     border-bottom: 8px solid #222;
  //     min-height: 200px;

  //     .img-benefits {
  //         max-height: 300px;
  //         object-fit: cover;
  //     }
  // }

  .benefit-container {
    display: flex;
    justify-content: center;
    border-bottom: 8px solid #222;
    min-height: 200px;

    .img-benefits {
      height: 130px;
      @media #{$large-mobile} {
        margin-bottom: 10px;
        padding-bottom: 10px;
      }
    }
    .img-benefits-2 {
      overflow: hidden;
      // object-fit: cover !important;
      @media only screen and (min-width: 769px) {
        // height: 292px;
        margin-bottom: 0;
        padding-bottom: 0;
        border-radius: 0 !important;
      }
      margin-bottom: 10px;
      padding-bottom: 10px;
      border-radius: 5px !important;
      @media #{$sm-layout} {
        width: 350px;
      }
      @media #{$large-mobile} {
        width: 250px;
      }
    }
    @media #{$sm-layout} {
      height: auto !important;
    }
  }

  .benefit-container-top-border {
    border-top: 8px solid #222;
  }

  .become-member-text {
    line-height: 17px !important;
    font-size: 13px;
  }

  .input-container-border-btn {
    padding: 10px;

    .input-border {
      font-weight: 500;
    }

    .input-border:focus {
      outline: none;
      font-weight: bold;
    }
  }

  @media (max-width: 769px) {
    .border-button {
      width: 80% !important;
    }

    .benefit-container {
      justify-content: center !important;

      .img-benefits {
        height: 100px !important;
      }
    }

    .center-mid-benefits {
      justify-content: center !important;
      text-align: center !important;
    }

    .btn-trial {
      font-size: 14px;
    }
  }

  @media (max-width: 480px) {
    .border-button {
      width: 90% !important;
    }
  }

  @media (min-height: 800px) {
    .main-container-general {
      min-height: 700px;
    }
  }

  @media (max-width: 548px) {
    .main-container-general {
      .logo {
        width: 100px;
      }
    }
  }
  // .text-orange {
  //   color: $primary-button-color;
  //   font-weight: bolder;
  // }
}
