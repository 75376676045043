.info-section {
  background-color: black;
  color: white;

  .card-icon {
    background-color: #3a3a3a;
    width: 90px;
    height: 90px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;

    .img {
      width: 40px;
      height: 40px;
    }

    .detail {
      width: 278px;
      font-weight: 300;
      font-size: 16px;
      line-height: 26px;
      text-align: center;
      color: antiquewhite;
    }
  }

  .font-size {
    font-size: 15px;
    white-space: nowrap !important;
  }

  .skeleton-loader-bg {
    background-color: #b6b2b2;
  }

  .logo {
    width: 170px !important;
    margin: 5px;
    cursor: pointer;

    img {
      width: 100%;
    }
  }

  .walletSvg {
    path:first-child {
      fill: $primaryColor !important;
    }
  }

  .groupSvg {
    path:nth-child(5n) {
      fill: $primaryColor !important;
    }
  }

  .money {
    path:nth-child(2n) {
      fill: $primaryColor !important;
    }
  }

  .exclusive {
    path:nth-child(4n) {
      fill: $primaryColor !important;
    }
  }

  .france {
    path:first-child {
      fill: $primaryColor !important;
    }
  }

  .support {
    path:first-child {
      fill: $primaryColor !important;
    }
  }
}
